@import '../variables';
@import '../mixin/scrollbar';

.thread-popover {
  // position: absolute;
  // top: 250px;
  // right: -220px;
  // z-index: 1;
  background-color: #1D253E;
  padding: 0px;
  border-radius: .3em;
  font-size: 12px;
  box-sizing: border-box;

  .login-required-note {
    color: #ccc;
    font-size: 11px;
    font-weight: bold;
    padding: 8px 8px 4px 8px;
  }

  .thread-card{
    cursor: default;
    position: relative;
    padding: 4px 4px;

    &__topbar {
      display: flex;
      height: 32px;
      align-items: center;

      &-avatar {
        padding: 0 4px;

        img {
          padding: 0;
          height: 24px;
          width: 24px;
          object-fit: cover;
        }
      }

      &-author {
        padding: 0 4px;
        color: #fff;
        border-radius: 4px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-time {
        color:#ddd;
        padding: 0 4px;
        font-size: 10px;
        white-space: nowrap;
      }
    }

    &__body {
      box-sizing: border-box;

      textarea {
        @include main-scroll-bar();

        margin: 2px 4px 4px 4px; //matches picture icon
        font-size: 11px;
        box-sizing: border-box;
        resize: none;
        background: #13162C;
        color: #fff;
        border:  none;
        outline: none;
        height: 48px;
        width: 180px;
        width: 100%;
        overflow: auto;
        

        &:read-only {
          background: transparent;
          border: none !important;
          box-shadow: unset !important;
          cursor: default;
        }

        &:focus {
          outline:  none;
          border-radius: 2px;
          border: 1px solid $simon-highlight-color;
          box-shadow: 0 0 0 4px rgba($simon-highlight-color, 0.2);
        }
      }
    }
  }

  .btn-reply {
    color: #fff;
    text-align: right;
    font-size: 11px;
    font-weight: bold;
    padding: 8px 8px 4px 8px;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .reactions {
    display: flex;
    color: #fff;
    justify-content: flex-start;
    align-items: center;
    padding: 0 4px;

    &__item {
      padding: 0 4px;
      cursor: pointer;

      &:hover {
        color: $simon-highlight-color;
      }
      
      &.reacted { 
        color: $simon-highlight-color;
      }
    }
  }
}

@import '../mixin/scrollbar';

#assembly {
  &.edit-mode {
    min-width: 710px;
    .video-edit-mode {
      .controls {
        .control {
          &__button {
            &__current-time-and-duration {
              font-size: 14px;
              position: absolute;
              left: 12px;
              bottom: 0;
              color: #fff;
            }
          }
        }
      }

      .video-container {
        margin-top: 20px;
        canvas {
          background: #000;
        }
      }
    }
  }
}

#time-line-container {
  background-color: #192037;
  // height: 30%;
  padding: 0;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1032;
  transition: all 0.2s;

  .time-line {
    &__topbar {
      display: flex;
      -moz-box-pack: justify;
      justify-content: space-between;
      background: #272e49;
      // margin-bottom: 20px;
      padding: 5px 28px;
      margin-left: -30px;
      align-items: center;
    }

    &__tools {
      flex: 1.5;
      display: flex;
      align-items: center;

      &__inactive {
        opacity: 0.3;
        pointer-events: none;
      }

      button {
        background: transparent;
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
      }
    }

    &__tool {
      margin-left: 20px;

      &:hover {
        filter: brightness(80%);
      }
    }

    &__time-display {
      flex: 1;
      display: flex;
      justify-content: center;
      user-select: none;
    }

    &__media-thumbnail {
      width: 100%;
      height: 100%;
      background-size: 55px 100%;
      background-repeat: repeat-x;
      background-position: center;
    }

    &__ruler {
      display: flex;
      height: 24px;
      border-bottom: 1px solid rgba(143, 155, 179, 0.5);
      position: relative;

      &-time {
        position: absolute;
        top: -8px;
        left: 8px;
        font-size: 12px;
        user-select: none;
      }

      &-item {
        position: absolute;
        border-left: 1px solid rgba(143, 155, 179, 0.5);
        padding-left: 4px;
        height: 24px;
        bottom: 0;
        cursor: pointer;

        &:hover {
          border-left: 1px solid rgba(143, 155, 179, 1);
        }

        &.short {
          bottom: 0;
          height: 8px;
        }
      }
    }

    &__seekbar {
      height: 100%;
      width: 1px;
      position: absolute;
      background: #2684fc;
      z-index: 10;
      left: 0;

      &-label {
        background: #2684fc;
        font-size: 10px;
        color: #fff;
        position: relative;
        top: -4px;
        left: -22px;
        padding: 4px;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
      }
    }

    &__wrapper {
      // display: flex;
      position: relative;

      &__section-1 {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 21px;
        // justify-content: space-evenly;
        // border-right: 1px solid;
        // padding: 10px;

        &__items {
          width: 130px;
          border-right: 1px solid rgba(143, 155, 179, 0.5);
          border-top: 1px solid rgba(143, 155, 179, 0.5);

          .item {
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 5px;

            svg {
              cursor: pointer;
            }
          }

          .hidden-item {
            visibility: hidden;
          }

          &.ruler {
            flex: 0.6;
            border-top: none;
            display: flex;

            .btn-add-caption {
              border: none;
              border-radius: 2px;
              outline: none;
              background: #c8247f;
              color: #fff;

              i {
                margin-right: 5px;
              }
            }
          }

          &.caption-track {
            flex: 0.35;
            min-height: 30px;

            .language-text {
              flex: 1;
              user-select: none;
            }
            .icon-container {
              flex: 0.2;
              .icon-invisibility {
                display: none;
              }
              .icon-visibility {
                display: block;
              }
            }

            &.hidden-track {
              .icon-container {
                .icon-invisibility {
                  display: block;
                }
                .icon-visibility {
                  display: none;
                }
              }
            }
          }

          &.video-track {
            flex: 1;
            min-height: 80px;

            .video-track-title {
              user-select: none;
            }
          }
          &.seekbar {
            flex: 0.75;
            min-height: 60px;

            .hidden-item {
              visibility: visible;
            }
          }
        }
      }

      &__section-2 {
        padding: 0 0 0 5px;
        margin-left: 126px;
      }
    }

    &__workplace {
      position: relative;
      height: auto;
      overflow: hidden;
      overflow-x: scroll;
      padding-top: 25px;
      // border-left: 1px solid;

      &::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
      }
    }

    &__audio {
      position: absolute;
      background: #763ba5;
      background-clip: content-box;
      height: 15px;
      border-radius: 2px;
      width: 50px;
      font-size: 12px;
      padding-left: 1px;

      &:nth-child(1) {
        padding-left: 0;
      }
    }

    &__video {
      display: flex;
      // align-items: center;
      // height: 62px;
      position: absolute;
      // margin-left: 1px;
      padding-left: 1px;

      &:nth-child(1) {
        padding-left: 0;
      }

      &__border-ltr {
        border-right: 3px solid #fff;
      }
      &__border-rtl {
        border-left: 3px solid #fff;
      }

      .segment-thumb {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // width: 100%;
        flex: 1;
        height: 55px;
        overflow: hidden;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }

        video {
          width: 100%;
          display: none;
        }
      }

      img {
        width: 70%;
        height: 60px;
        object-fit: cover;
      }

      &__playing-border {
        border: 3px solid #c8247f;
        border-radius: 5px;
      }

      &__anchor {
        background: rgba(143, 155, 179, 0.2);
        border: none;
        height: 55px;
        outline: none;
        width: 6px;

        &__left {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          // cursor: ew-resize;
        }

        &__right {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          // cursor: ew-resize;
          position: relative;
          display: flex;
          align-items: center;

          .tool-icon {
            cursor: pointer;
            position: absolute;
            left: 1px;

            &.last {
              display: none;
            }
          }
        }
      }
    }

    &__track {
      display: flex;
      position: relative;
      align-items: center;
      border-bottom: 1px solid rgba(143, 155, 179, 0.5);

      &__audio-track {
        height: 30px;
        visibility: visible;

        &.hidden-track {
          visibility: hidden;
        }
      }

      &__video-track {
        height: 80px;
        &.hidden-track {
          visibility: hidden;
        }
      }
    }

    &__scrollbar {
      &-wrapper {
        position: relative;
        height: 10px;
        width: 100%;
        background: #13162c;
        display: flex;
        align-items: center;
        border-radius: 10px;
        border: 1px solid rgba(143, 155, 179, 0.3);
      }

      position: absolute;
      left: 0px;

      &-left-circle {
        left: 0;
        top: 0;
      }

      &-right-circle {
        right: 0;
        top: 0;
        cursor: ew-resize;
      }

      &-stick {
        height: 10px;
        background: #8f9bb3;
        border-radius: 5px;
      }

      &-right-circle,
      &-left-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #13162c;
        position: absolute;
      }
    }

    &__chapter-label {
      position: absolute;
      top: 37px;
      font-size: 10px;
      color: #fff;
    }

    &__chapter-label-bg {
      background: rgba(143, 162, 240, 0.3);
      opacity: 0.7;
      width: 100%;
      height: 12px;
      border-radius: 4px;
    }

    &__chapter-label-text {
      position: absolute;
      top: 0;
      left: 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
  }
}

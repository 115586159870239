@import './fonts/avenir/index.scss';
@import './fonts/gotham/index.scss';

html,
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  * {
    &:not(.fa) {
      font-family: 'avenir', 'Gotham', sans inherit !important;
    }

    &:not(input, textarea) {
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none; /* Likely future */
    }
  }
}

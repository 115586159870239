.edit-mode {
  .popover {
    border: none;
  }
  .popover-inner {
    width: 230px;
  }
  .thread-popover {
    @include main-scroll-bar();
    background-color: transparent;
    height: calc(100vh - 400px);
    overflow-y: auto;
  }
  .thread-card {
    background-color: #1d253e;
    margin-bottom: 10px;
    border-radius: 10px;
    // height: 78px;
    &:last-child {
      margin-bottom: 0x;
    }
  }
  .ctn-thread-cards {
    position: relative;
    z-index: 1;
  }
  .ctn-thread-cards:last-child {
    margin-bottom: 0x;
  }
  .body-comment-edit-mode {
    width: 100%;
    overflow: visible;
    span {
      top: 11px;
    }
  }
  .reply {
    position: relative;
    background-color: transparent;
    border-radius: 0 0 10px 10px;
    margin-top: -40px;
    z-index: 2;
  }
  .popover-body {
    // background-color: #13162c;
  }
}
.icon-note-img {
  position: absolute;
  width: 13px !important;
  height: 13px !important;
  right: 10px;
  top: 5px;
  cursor: pointer;
}

.show {
  opacity: 1;
  // background-color: #1D253E;
  // box-shadow: 2px 2px 2px grey;
  //border: 1px solid #ffffff10;
  .popover {
    background: transparent;
  }

  .popover-body {
    padding: 0;
    background-color: transparent;
  }

  .popover.bs-popover-right {
    margin-left: 5px;
  }

  .popover.bs-popover-right > .arrow {
    left: -22px;
    border-right-color: #999;
    &::after {
      content: '';
      left: 1px;
      bottom: -10px;
      border-left-width: 0;
      border-right-color: #fff;
    }
  }

  .popover-speaker {
    * {
      color: #fff;
    }
    textarea,
    input {
      background: transparent;
      border: none;
      outline: none;
      border-radius: 2px;

      &:focus {
        border: 1px solid #c8247f !important;
        box-shadow: 0 0 0 2pt #c8247f50 !important;
      }
    }
  }

  .annotation {
    * {
      color: #fff !important;
    }
    background: #1d253e;
    margin: 0;

    textarea,
    input {
      background: transparent;
      border-radius: 2px;
      font-size: 11px;

      &:focus:not(.no-focus) {
        border: 1px solid #c8247f !important;
        box-shadow: 0 0 0 2pt #c8247f50 !important;
      }
    }

    &__user-name {
      padding: 0;
      box-shadow: #c8247f !important;
    }

    &__avatar {
      height: 24px;
      width: 24px;
      padding-right: 8px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
    }

    &__user-info {
      display: flex;
      height: 24px;
      padding: 0 8px;
      margin-bottom: 8px;
      align-items: center;
    }
  }
}

.popover {
  .arrow {
    display: none !important;
  }
  z-index: 500;
}

.bs-popover-auto[x-placement^='right'],
.bs-popover-right {
  background: transparent;
  border: none;
}
